<template>
    <div class="mb-16">
        <v-row justify="start" align="center" class="mx-0 my-4">
            <h2 class="dashboard">Посещения клиентов, после которых они не вернулись на услугу</h2>
        </v-row>
        <DidntReturnVisitsSubHeader :subHeaderData="subHeaderData"></DidntReturnVisitsSubHeader>
        <v-data-table :headers="headers" :items="chartData" @current-items="setSubHeaderData">
            <template v-slot:[`item.client_name_with_num`]="{ item }">
                <v-menu offset-x v-if="item.href_info">
                    <template v-slot:activator="{ on, attrs }">
                        <a v-bind="attrs" v-on="on">{{ item.client_name_with_num }}</a>
                    </template>
                    <div>
                        <ClientIconsMenu :hrefInfo="item.href_info" :connector="connector"></ClientIconsMenu>
                    </div>
                </v-menu>
                <span v-else> {{ item.client_name_with_num }}</span>
            </template>
            <template v-slot:[`item.visit_date`]="{ item }">
                <span>{{ moment(item.visit_date).format(dateFormat) }}</span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
/* Посещения клиентов, после которых они не вернулись на услугу */
import { openInNewTab } from '../../../../../utils'
import HorizonalBarChart from '@/components/charts/HorizontalBarChart.js'
import SmallHint from '@/components/SmallHint.vue'
import DidntReturnVisitsSubHeader from './subHeaders/DidntReturnVisitsSubHeader.vue'
import ClientIconsMenu from '@root/src/components/iconLinks/ClientIconsMenu.vue'
import { CONNECTOR_TYPES } from '@root/src/vars/general'

export default {
    name: 'DidntReturnVisits',
    components: {
        HorizonalBarChart,
        SmallHint,
        DidntReturnVisitsSubHeader,
        ClientIconsMenu,
    },
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        dateFormat: 'DD MMMM YYYY г.',
        headers: [
            {
                text: 'Клиент',
                align: 'start',
                value: 'client_name_with_num',
            },
            {
                text: 'Мастер',
                align: 'start',
                value: 'staff_name',
            },
            {
                text: 'Услуга',
                align: 'start',
                value: 'service_name',
            },
            {
                text: 'Последняя дата посещения',
                value: 'visit_date',
                width: '160px',
            },
            {
                text: 'Филиал',
                value: 'project_name',
            },
        ],
        fullBarWidth: 200,
        subHeaderData: {},
    }),
    computed: {
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        connector() {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
    },
    methods: {
        openInNewTab,
        setSubHeaderData(curItems) {
            if (curItems.length > 0) {
                this.subHeaderData = {
                    clientName: curItems[0].client_name_with_num,
                    serviceName: curItems[0].service_name,
                    date: curItems[0].visit_date,
                }
            }
        },
    },

    created() {},
}
</script>
<style lang="sass" scoped>
.v-application
    .v-menu__content
        overflow: visible !important
        contain: none !important
</style>
