<template>
    <div class="mb-16">
        <v-row justify="start" align="center" class="mx-0 my-4">
            <h2 class="dashboard">Удержание по услугам</h2>
        </v-row>
        <ReturnabilityByServiceSubHeader :subHeaderData="subHeaderData"></ReturnabilityByServiceSubHeader>
        <v-data-table
            :sort-by.sync="sortColumn"
            :sort-desc.sync="sortDesc"
            :headers="headers"
            :items="items"
            @current-items="setSubHeaderData"
        >
            <template v-slot:[`item.att_count`]="{ item }">
                <div class="avg-returnability">
                    <span style="width: 50px">{{ item.att_count }}</span>
                    <div
                        class="bar ml-4"
                        :style="
                            `width:${(item.att_count / maxVisitsCount) *
                                100 *
                                (fullBarWidth / 100)}px; background-color:${CHART_COLORS.COLOR1}`
                        "
                    ></div>
                </div>
            </template>
            <template v-slot:[`item.must_to_return_count`]="{ item }">
                <div class="avg-returnability">
                    <span style="width: 50px">{{ item.must_to_return_count }}</span>
                    <div
                        class="bar ml-4"
                        :style="
                            `width:${(item.must_to_return_count / maxShouldReturn) *
                                100 *
                                (fullBarWidth / 100)}px; background-color:${CHART_COLORS.COLOR3}`
                        "
                    ></div>
                </div>
            </template>
            <template v-slot:[`item.rate`]="{ item }">
                <div class="avg-returnability">
                    <span style="width: 50px">{{ item.rate + '%' }}</span>
                    <div
                        class="bar ml-4"
                        :style="
                            `width:${item.rate * (fullBarWidth / 100)}px; background-color:${
                                CHART_COLORS.COLOR2
                            } `
                        "
                    ></div>
                </div> </template
        ></v-data-table>
    </div>
</template>

<script>
import { maxBy } from 'lodash'
import HorizonalBarChart from '@/components/charts/HorizontalBarChart.js'
import { groupBy } from '../../../../../utils'
import ReturnabilityByServiceSubHeader from './subHeaders/ReturnabilityByServiceSubHeader.vue'
import { toPercent } from '../../helpers'
import { CHART_COLORS } from '@/vars/charts.js'
export default {
    name: 'GCBRetByServiceChart',
    components: {
        HorizonalBarChart,
        ReturnabilityByServiceSubHeader,
    },
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
        dateFrom: {
            type: String,
        },
        dateTo: {
            type: String,
        },
    },
    data: () => ({
        CHART_COLORS,
        hintText: 'Процент возвращения клиентов в салон после предоставленной услуги за выбранный период.',
        sortColumn: 'avgReturnability',
        sortDesc: true,
        headers: [
            {
                text: 'Услуга',
                align: 'start',
                value: 'service_name',
            },
            {
                text: 'Общее количество посещений',
                align: 'start',
                value: 'att_count',
            },
            {
                text: 'Количество посещений на которые должен быть возврат',
                value: 'must_to_return_count',
            },
            {
                text: 'Из них вернулось',
                value: 'returned_count',
            },
            {
                text: 'Среднее удержание',
                value: 'rate',
            },
        ],
        fullBarWidth: 150,
        subHeaderData: {},
    }),
    computed: {
        items() {
            return this.chartData.filter(el => el.rate > 0)
        },
        maxVisitsCount() {
            return maxBy(this.chartData, 'att_count').att_count
        },
        maxShouldReturn() {
            return maxBy(this.chartData, 'must_to_return_count').must_to_return_count
        },
    },
    methods: {
        setSubHeaderData(curItems) {
            if (curItems.length > 0)
                this.subHeaderData = {
                    startDate: this.dateFrom,
                    endDate: this.dateTo,
                    name: curItems[0].service_name,
                    value: curItems[0].rate ? curItems[0].rate + '%' : '',
                    count: curItems[0].att_count,
                    shouldReturnCount: curItems[0].must_to_return_count,
                }
        },
    },

    created() {},
}
</script>
<style lang="sass" scoped>
.avg-returnability
    display: flex
    flex-direction: row
    align-items: center
.bar
    height: 20px
</style>
