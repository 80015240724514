
import BaseInputOutlined from '@/components/inputs/BaseInputOutlined.vue'
import { v4 as uuidv4 } from 'uuid'
import { computed, defineComponent, ref, shallowRef, watch, PropType } from 'vue'
export default defineComponent({
    components: { BaseInputOutlined },
    props: {
        value: { type: Array as PropType<number[]>, default: () => [] },
        min: { type: Number, default: 0 },
        max: { type: Number, default: 100 },
    },
    setup(props, { emit }) {
        const update = (val: number[]) => {
            emit('input', val)
        }
        const activatorId = 'slider-input' + '-' + uuidv4().slice(0, 10)
        const onNumberInput = (val: string, type: 'left' | 'right') => {
            if (type === 'left') {
                update([Number(val), props.value[1]])
            } else if (type === 'right') {
                update([props.value[0], Number(val)])
            }
        }
        const onNumberUpdate = () => {
            console.log('upd', props.value)

            if (props.value[0] > props.value[1]) {
                update([props.value[1], props.value[0]])
            }
            if (props.value[1] > props.max) {
                update([props.value[0], props.max])
            }
            if (props.value[0] < props.min) {
                update([props.min, props.value[1]])
            }

            console.log('props.value[', props.value)
        }
        return { update, onNumberInput, onNumberUpdate, activatorId }
    },
})
