<template>
    <div>
        <v-row>
            <v-col>
                <div class="value-wrapper" :class="returnabilityDiff >= 0 ? 'plus-border ' : 'minus-border'">
                    <div class="value-content">
                        <span class="value-name">Удержание</span>
                        <div>
                            <span class="result-value">{{
                                chartData.returnability ? chartData.returnability + '%' : ''
                            }}</span>
                        </div>
                        <span :class="returnabilityDiff >= 0 ? 'diff-plus ' : 'diff-minus'">
                            {{ getDiffText() }}
                        </span>
                    </div>
                </div>
            </v-col>
            <v-col>
                <div class="value-wrapper">
                    <div class="value-content">
                        <span class="value-name">Количество сотрудников</span>
                        <div>
                            <span class="result-value">{{ chartData.staff_count }}</span>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col>
                <div class="value-wrapper">
                    <div class="value-content">
                        <span class="value-name">Количество групп услуг</span>
                        <div>
                            <span class="result-value">{{ chartData.group_count }}</span>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col>
                <div class="value-wrapper">
                    <div class="value-content">
                        <span class="value-name">Количество услуг</span>
                        <div>
                            <span class="result-value">{{ chartData.service_count }}</span>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import _ from 'lodash'
export default {
    name: 'GCBRetResultValues',
    props: {
        chartData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({}),
    computed: {
        returnabilityDiff() {
            return this.chartData.returnability - this.chartData.ex_returnability
        },
    },
    methods: {
        getDiffText() {
            if (_.isNil(this.returnabilityDiff)) return ''
            if (this.returnabilityDiff >= 0) return `+${this.returnabilityDiff}%`
            if (this.returnabilityDiff < 0) return `${this.returnabilityDiff}%`
        },
    },
    created() {},
}
</script>
<style lang="sass" scoped>
.result-value
    font-size: 42px !important
.value-content
    padding: 16px
.value-name
    font-size: 14px
.value-wrapper
    height: 138px !important
    background-color: #2A3B510B
    display: flex
    flex-direction: column
.plus-border
    border-bottom: 2px solid #00B74F
.minus-border
    border-bottom: 2px solid #E20029
.diff-plus
    color: #00B74F
.diff-minus
    color: #E20029
.icon
    color: inherit
</style>
