import { HorizontalBar, mixins } from 'vue-chartjs'
import datalabelsPlugin from 'chartjs-plugin-datalabels'
const { reactiveProp } = mixins

export default {
    extends: HorizontalBar,
    mixins: [reactiveProp],
    props: ['options'],
    mounted() {
        this.addPlugin(datalabelsPlugin)
        this.renderChart(this.chartData, this.options)
    },
}
