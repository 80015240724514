<template>
    <div>
        <p>
            Например,
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.clientName }}</span> с
            <span class="gcb-returnability-subheader-highlight-text">{{
                formatDate(subHeaderData.date)
            }}</span>
            больше не приходит на услугу «<span class="gcb-returnability-subheader-highlight-text">{{
                subHeaderData.serviceName
            }}</span
            >». Важно, что клиент может посещать другие услуги
        </p>
    </div>
</template>

<script>
export default {
    name: 'DidntReturnVisitsSubHeader',

    props: {
        subHeaderData: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        dateFormat: 'DD.MM.YYYY',
    }),
    methods: {
        formatDate(date) {
            return this.moment(date).format(this.dateFormat)
        },
    },
}
</script>
<style lang="sass" scoped></style>
