<template>
    <div class="small mb-16">
        <v-row justify="start" align="center" class="mx-0 my-4">
            <h2 class="dashboard">Удержание по группе услуг</h2>
        </v-row>
        <ReturnabilityByServiceGroupSubHeader
            :subHeaderData="subHeaderData"
        ></ReturnabilityByServiceGroupSubHeader>
        <v-row justify="end" class="mx-0 my-6"> <SortLabel v-model="sort"></SortLabel></v-row>
        <HorizonalBarChart
            :chart-data="datacollection"
            :options="options"
            :styles="chartStyles"
        ></HorizonalBarChart>
    </div>
</template>

<script>
import SortLabel from './SortLabel'
import HorizonalBarChart from '@/components/charts/HorizontalBarChart.js'
import SmallHint from '@/components/SmallHint.vue'
import { groupBy } from '../../../../../utils'
import ReturnabilityByServiceGroupSubHeader from './subHeaders/ReturnabilityByServiceGroupSubHeader.vue'
import { toPercent } from '../../helpers'
export default {
    name: 'GCBRetByServiceChart',
    components: {
        HorizonalBarChart,
        SortLabel,
        SmallHint,
        ReturnabilityByServiceGroupSubHeader,
    },
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
        dateFrom: {
            type: String,
        },
        dateTo: {
            type: String,
        },
    },
    data: () => ({
        hintText:
            'Процент возвращения клиентов в салон после предоставленных услуг в рамках групп услуг за выбранный период.',
        sort: 'desc',
        height: 600,
        options: {
            maintainAspectRatio: false,
            plugins: {
                datalabels: {
                    color: '#ffffff',
                    anchor: 'end',
                    align: 'start',
                    clip: true,
                    formatter: value => value.x.toFixed(0) + '%',
                },
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                            callback: value => value.toFixed(0) + '%',
                        },
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        const element = data['datasets'][0]['data'][tooltipItem['index']]
                        return [
                            `Среднее удержание: ${element.x.toFixed(0)}%`,
                            `Должно было вернуться: ${element.mustToReturnCount}, из них вернулось ${element.returnedCount}`,
                        ]
                    },
                },
            },
        },
    }),
    computed: {
        subHeaderData() {
            return {
                startDate: this.dateFrom,
                endDate: this.dateTo,
                name: this.datacollection.labels[0],
                value: this.datacollection.datasets[0].data[0].x + '%',
            }
        },
        datacollection() {
            const labels = []
            const data = []
            const sortedData = _.orderBy(this.chartData, 'rate', this.sort).filter(el => el.rate > 0)
            sortedData.forEach(el => {
                labels.push(el.service_group)
                data.push({
                    x: el.rate,
                    mustToReturnCount: el.must_to_return_count,
                    returnedCount: el.returned_count,
                })
            })
            return {
                labels,
                datasets: [
                    {
                        label: 'Среднее удержание',
                        backgroundColor: '#378EFF',
                        data,
                    },
                ],
            }
        },
        chartStyles() {
            return {
                height: `${this.height}px`,
                position: 'relative',
            }
        },
    },
    methods: {},

    created() {},
}
</script>
<style lang="sass" scoped>
.small
    max-width: 1016px
</style>
