<template>
    <div>
        <p>
            Например, если клиент за период с
            <span class="gcb-returnability-subheader-highlight-text">{{
                formatDate(subHeaderData.startDate)
            }}</span>
            по
            <span class="gcb-returnability-subheader-highlight-text">{{
                formatDate(subHeaderData.endDate)
            }}</span>
            приходил на услугу из группы
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.name }}</span
            >, то в
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.value }}</span> случаев
            был повторный визит на эту или подобную услугу. Важно, что повторное посещение могло быть в этом
            же периоде или позже
        </p>
    </div>
</template>


<script>
export default {
    name: 'ReturnabilityByServiceGroupSubHeader',

    props: {
        subHeaderData: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        dateFormat: 'DD.MM.YYYY',
    }),
    methods: {
        formatDate(date) {
            return this.moment(date).format(this.dateFormat)
        },
    },
}
</script>
<style lang="sass" scoped>
</style>
