var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-16"},[_c('v-row',{staticClass:"mx-0 my-4",attrs:{"justify":"start","align":"center"}},[_c('h2',{staticClass:"dashboard"},[_vm._v("Удержание по услугам")])]),_c('ReturnabilityByServiceSubHeader',{attrs:{"subHeaderData":_vm.subHeaderData}}),_c('v-data-table',{attrs:{"sort-by":_vm.sortColumn,"sort-desc":_vm.sortDesc,"headers":_vm.headers,"items":_vm.items},on:{"update:sortBy":function($event){_vm.sortColumn=$event},"update:sort-by":function($event){_vm.sortColumn=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"current-items":_vm.setSubHeaderData},scopedSlots:_vm._u([{key:`item.att_count`,fn:function({ item }){return [_c('div',{staticClass:"avg-returnability"},[_c('span',{staticStyle:{"width":"50px"}},[_vm._v(_vm._s(item.att_count))]),_c('div',{staticClass:"bar ml-4",style:(`width:${(item.att_count / _vm.maxVisitsCount) *
                            100 *
                            (_vm.fullBarWidth / 100)}px; background-color:${_vm.CHART_COLORS.COLOR1}`)})])]}},{key:`item.must_to_return_count`,fn:function({ item }){return [_c('div',{staticClass:"avg-returnability"},[_c('span',{staticStyle:{"width":"50px"}},[_vm._v(_vm._s(item.must_to_return_count))]),_c('div',{staticClass:"bar ml-4",style:(`width:${(item.must_to_return_count / _vm.maxShouldReturn) *
                            100 *
                            (_vm.fullBarWidth / 100)}px; background-color:${_vm.CHART_COLORS.COLOR3}`)})])]}},{key:`item.rate`,fn:function({ item }){return [_c('div',{staticClass:"avg-returnability"},[_c('span',{staticStyle:{"width":"50px"}},[_vm._v(_vm._s(item.rate + '%'))]),_c('div',{staticClass:"bar ml-4",style:(`width:${item.rate * (_vm.fullBarWidth / 100)}px; background-color:${
                            _vm.CHART_COLORS.COLOR2
                        } `)})])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }