<template>
    <div>
        <p>
            Например, если клиент за период с
            <span class="gcb-returnability-subheader-highlight-text">{{
                formatDate(subHeaderData.startDate)
            }}</span>
            по
            <span class="gcb-returnability-subheader-highlight-text">{{
                formatDate(subHeaderData.endDate)
            }}</span>
            приходил на услугу «
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.name }}</span
            >», то в
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.value }}</span> случаев
            был повторный визит на эту или подобную услугу. При этом всего услугу за период посетили
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.count }}</span>

            {{ declOfNum(subHeaderData.count, texts) }}. И на
            <span class="gcb-returnability-subheader-highlight-text">{{
                subHeaderData.shouldReturnCount
            }}</span>
            {{ declOfNum(subHeaderData.shouldReturnCount, texts2) }} должен был быть возврат. Важно, что
            повторное посещение могло быть в этом же периоде или позже, а количество визитов не равно
            количеству клиентов
        </p>
    </div>
</template>


<script>
import { declOfNum } from '@/utils'
export default {
    name: 'ReturnabilityByServiceSubHeader',
    props: {
        subHeaderData: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        dateFormat: 'DD.MM.YYYY',
        texts: ['раз', 'раза', 'раз'],
        texts2: ['посещение', 'посещения', 'посещений'],
    }),
    methods: {
        declOfNum,
        formatDate(date) {
            return this.moment(date).format(this.dateFormat)
        },
    },
}
</script>
<style lang="sass" scoped>
</style>
