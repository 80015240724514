<template>
    <div class="small mb-16">
        <v-row justify="start" align="center" class="mx-0 my-4">
            <h2 class="dashboard">Динамика среднего удержания</h2>
        </v-row>
        <ReturnabilityDynamicSubHeader :subHeaderData="subHeaderData"> </ReturnabilityDynamicSubHeader>
        <LineChart v-if="!billingEnabled || (billingEnabled && currentTariff)" :chart-data="datacollection" :options="options" :styles="chartStyles"></LineChart>
        <DemoSubView v-else :backroundLink="require('@/assets/img/project/expiredTariff/dynamics-of-average-retention.png')"></DemoSubView>
    </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart.js'
import moment from 'moment'
import ReturnabilityDynamicSubHeader from './subHeaders/ReturnabilityDynamicSubHeader.vue'
import DemoSubView from '@root/src/views/GCB2/subViews/DemoSubView.vue'
import { useProject } from '@root/src/hooks/useProject'
import { useBillingStore } from '@root/src/store/stores/billing'
export default {
    name: 'GCBRetByServiceChart',
    components: {
        LineChart,
        ReturnabilityDynamicSubHeader,
        DemoSubView
    },
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
        prevChartData: {
            type: Array,
            default: () => [],
        },
        dateFrom: {
            type: String,
        },
        dateTo: {
            type: String,
        },
    },
    setup(props, context){
        const { project } = useProject()
        const billingEnabled = project.billingEnabled
        const billingStore = useBillingStore()
        const currentTariff = billingStore.getCurrentTariffState(project.value.id)
        return{
            currentTariff,
            billingEnabled
        }
    },
    data: () => ({
        hintText:
            'Сравнение процента возвращения клиентов в салон после предоставленных услуг за выбранный период по отношениею к предыдущему аналогичному периоду.',
        height: 600,
        options: {
            legend: {
                labels: {
                    usePointStyle: true,
                },
            },
            plugins: {
                datalabels: {
                    color: '#465468',
                    anchor: 'end',
                    align: 'top',
                    //clip: true,
                    formatter: (value, context) => value.y.toFixed(0) + '%',
                },
            },
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    title: (tooltipItem, data) => {
                        if (tooltipItem[0].datasetIndex === 0) {
                            return tooltipItem[0].label
                        } else {
                            //Отнимаем один год в тултипе, если это данные за предыдущий период
                            const splittedTooltipLabel = tooltipItem[0].label.split(' ')
                            return `${splittedTooltipLabel[0]} ${Number(splittedTooltipLabel[1]) - 1} `
                        }
                    },
                    label: function(tooltipItem, data) {
                        return (
                            data['datasets'][tooltipItem.datasetIndex]['data'][
                                tooltipItem['index']
                            ].y.toFixed(0) + '%'
                        )
                    },
                },
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                            callback: value => value.toFixed(0) + '%',
                        },
                    },
                ],
            },
        },
    }),
    computed: {
        datacollection() {
            const labels = this.getAllMonthsBetweenPeriod(this.dateFrom, this.dateTo)
            const currentData = this.chartData.map(el =>
                Object.assign({}, el, { formattedDate: moment(el.date).format('MMM YYYY') })
            )
            const prevData = this.prevChartData.map(el =>
                Object.assign({}, el, { formattedDate: moment(el.date).format('MMM YYYY') })
            )
            const data1 = []
            const data2 = []
            labels.forEach(label => {
                const point1Data = currentData.find(el => el.formattedDate === label)
                const point1 = point1Data ? { x: label, y: point1Data.rate } : { x: label, y: 0 }
                data1.push(point1)
                const point2Data = prevData.find(
                    el =>
                        moment(el.date)
                            .add(1, 'year')
                            .format('MMM YYYY') === label
                )
                const point2 = point2Data ? { x: label, y: point2Data.rate } : { x: label, y: 0 }
                data2.push(point2)
            })
            return {
                labels,
                datasets: [
                    {
                        label: 'Среднее удержание',
                        borderColor: '#378EFF',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        lineTension: 0,
                        data: data1,
                        pointBackgroundColor: 'rgba(255,255,255,1)',
                    },
                    {
                        label: 'Среднее удержание (предыдущий период)',
                        borderColor: 'rgba(55, 142, 255, 0.2)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        lineTension: 0,
                        data: data2,
                        pointBackgroundColor: 'rgba(255,255,255,1)',
                    },
                ],
            }
        },
        chartStyles() {
            return {
                height: `${this.height}px`,
                position: 'relative',
            }
        },
        subHeaderData() {
            //Отнимаем один год в т.к. это данные за предыдущий период
            const splittedTooltipLabel = this.datacollection.datasets[1].data[0].x.split(' ')
            const labelPrev = `${splittedTooltipLabel[0]} ${Number(splittedTooltipLabel[1]) - 1} `

            return {
                startDates: this.dateFrom,
                endDates: this.dateTo,
                labelCur: this.datacollection.datasets[0].data[0].x,
                valueCur: this.datacollection.datasets[0].data[0].y
                    ? this.datacollection.datasets[0].data[0].y + '%'
                    : '',
                labelPrev: labelPrev,
                valuePrev: this.datacollection.datasets[1].data[0].y
                    ? this.datacollection.datasets[1].data[0].y + '%'
                    : '',
            }
        },
    },
    methods: {
        getAllMonthsBetweenPeriod(dateFrom, dateTo) {
            const dateStart = moment(dateFrom)
            const dateEnd = moment(dateTo)
            const months = []

            while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
                if (dateStart.format('MMM YYYY') === 'Invalid date') {
                    throw new Error('invalid date ')
                }
                months.push(dateStart.format('MMM YYYY'))
                dateStart.add(1, 'month')
            }
            return months
        },
    },

    created() {},
}
</script>
<style lang="sass" scoped>
.small
    max-width: 1016px
</style>
