<template>
    <div>
        <p>
            Например, в
            <span class="gcb-returnability-subheader-highlight-text">{{
                declDate(subHeaderData.labelCur)
            }}</span>
            года среднее удержание составило
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.valueCur }}</span
            >, а за год до этого — в
            <span class="gcb-returnability-subheader-highlight-text">{{
                declDate(subHeaderData.labelPrev)
            }}</span>
            —
            <span class="gcb-returnability-subheader-highlight-text">{{ subHeaderData.valuePrev }}</span>
        </p>
    </div>
</template>

<script>
import { DECLINATION_SHORT_MONTH_NAMES } from '@/vars/general'
export default {
    name: 'ReturnabilityDynamicSubHeader',

    props: {
        subHeaderData: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({}),
    methods: {
        declDate(dateStr) {
            const month = dateStr.split(' ')[0]
            const year = dateStr.split(' ')[1]
            return `${DECLINATION_SHORT_MONTH_NAMES[month]} ${year}`
        },
    },
}
</script>
<style lang="sass" scoped></style>
