<template>
    <div>
        <p>
            Например, если клиент за период с
            <span class="gcb-returnability-subheader-highlight-text">{{ formatDate(startDate) }}</span> по
            <span class="gcb-returnability-subheader-highlight-text">{{ formatDate(endDate) }}</span> приходил
            к сотруднику « <span class="gcb-returnability-subheader-highlight-text">{{ name }}</span
            >», то в <span class="gcb-returnability-subheader-highlight-text">{{ value }}</span> случаев был
            повторный визит на эту или подобную услугу. Важно, что повторное посещение могло быть в этом же
            периоде или позже, к этому или к любому другому сотруднику
        </p>
    </div>
</template>

<script>
export default {
    name: 'ReturnabilityByStaffSubHeader',

    props: {
        name: {
            type: String,
            default: null,
        },
        value: {
            type: String,
            default: null,
        },
        startDate: {
            type: String,
            default: null,
        },
        endDate: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        dateFormat: 'DD.MM.YYYY',
    }),
    methods: {
        formatDate(date) {
            return this.moment(date).format(this.dateFormat)
        },
    },
}
</script>
<style lang="sass" scoped></style>
