<template>
    <div class="small mb-16">
        <v-row justify="start" align="center" class="mx-0 my-4">
            <h2 class="dashboard">Удержание по сотрудникам</h2>
        </v-row>
        <ReturnabilityByStaffSubHeader
            :name="subHeaderName"
            :value="subHeaderValue"
            :startDate="dateFrom"
            :endDate="dateTo"
        ></ReturnabilityByStaffSubHeader>
        <v-row class="mx-0 my-6" justify="end"> <SortLabel v-model="sort"></SortLabel></v-row>
        
        <HorizonalBarChart v-if="!project.billingEnabled || (project.billingEnabled && currentTariff)"
            :chart-data="datacollection"
            :options="options"
            :styles="chartStyles"
        ></HorizonalBarChart>
        <DemoSubView v-else :backroundLink="require('@/assets/img/project/expiredTariff/retention-by-employee.png')"></DemoSubView>
    </div>
</template>

<script>
import SortLabel from './SortLabel'
import HorizonalBarChart from '@/components/charts/HorizontalBarChart.js'
import ReturnabilityByStaffSubHeader from './subHeaders/ReturnabilityByStaffSubHeader.vue'
import DemoSubView from '@root/src/views/GCB2/subViews/DemoSubView.vue'
import { useProject } from '@root/src/hooks/useProject'
import { useBillingStore } from '@root/src/store/stores/billing'

export default {
    name: 'GCBRetByServiceChart',
    components: {
        HorizonalBarChart,
        SortLabel,
        ReturnabilityByStaffSubHeader,
        DemoSubView
    },
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
        dateFrom: {
            type: String,
        },
        dateTo: {
            type: String,
        },
    },
    setup(props, context){
        const { project } = useProject()
        const billingStore = useBillingStore()
        const currentTariff = billingStore.getCurrentTariffState(project.value.id)
        return{
            currentTariff,
            project
        }
    },
    data: () => ({
        sort: 'desc',
        height: 600,
        options: {
            maintainAspectRatio: false,
            plugins: {
                datalabels: {
                    color: '#ffffff',
                    anchor: 'end',
                    align: 'start',
                    clip: true,
                    formatter: value => value.toFixed(0) + '%',
                },
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                            callback: value => value.toFixed(0) + '%',
                        },
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return data['datasets'][0]['data'][tooltipItem['index']].toFixed(0) + '%'
                    },
                },
            },
        },
    }),
    computed: {
        subHeaderName() {
            return this.datacollection.labels[0]
        },
        subHeaderValue() {
            return this.datacollection.datasets[0].data[0]
                ? this.datacollection.datasets[0].data[0] + '%'
                : ''
        },
        datacollection() {
            const labels = []
            const data = []
            const sortedData = _.orderBy(this.chartData, 'rate', this.sort).filter(el => el.rate > 0)
            sortedData.forEach(el => {
                labels.push(el.staff_name)
                data.push(el.rate)
            })
            return {
                labels,
                datasets: [
                    {
                        label: 'Среднее удержание',
                        backgroundColor: '#378EFF',
                        data,
                    },
                ],
            }
        },
        chartStyles() {
            return {
                height: `${this.height}px`,
                position: 'relative',
            }
        },
    },
    methods: {},
}
</script>
<style lang="sass" scoped>
.small
    max-width: 1016px
</style>
