<template>
    <v-btn class="sort-label" outlined @click="changeSortDirection">
        <v-icon>{{ icon }}</v-icon>
        <span class="ml-1">{{ text }} </span>
    </v-btn>
</template>


<script>
export default {
    name: 'SortLabel',
    data: () => ({
        sort: 'desc',
    }),
    props: {
        value: {
            type: null,
            default: undefined,
        },
    },
    computed: {
        text() {
            if (this.sort === 'desc') {
                return 'По убыванию'
            } else {
                return 'По Возрастанию'
            }
        },
        icon() {
            if (this.sort === 'desc') {
                return 'mdi-arrow-down'
            } else {
                return 'mdi-arrow-up'
            }
        },
    },
    methods: {
        changeSortDirection() {
            this.sort === 'asc' ? (this.sort = 'desc') : (this.sort = 'asc')
            this.$emit('input', this.sort)
        },
    },
    created() {},
}
</script>
<style lang="sass" scoped>
.sort-label
    cursor: pointer
    display: flex
    align-items: center
</style>
